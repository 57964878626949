import React from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { IDeviceStatus } from 'shared/model/device.model';
import { Alert } from '@material-ui/lab';
import { useOperationSuggestedInfo } from 'modules/devices/fields/OperationSuggested';
import { Warning } from '@material-ui/icons';
import { useWarningCauseInfo } from 'modules/devices/fields/WarningCause';

/**
 * Detailed explanation of the device problems, or calibrating status.
 * Only for Silo
 */
const DeviceStatusExplanation = ({
  status,
  metadata,
  installDate
}: {
  status: IDeviceStatus;
  metadata?: any;
  installDate?: Moment | undefined;
}) => {
  const { t } = useTranslation();
  const fifteenDaysAgo = moment().subtract(15, 'days').toDate();
  const isInstalledLessThan15DaysAgo = installDate && moment(installDate).isAfter(fifteenDaysAgo);

  // Voir la logique spécifiée
  // https://www.notion.so/nanolike/NTSK-3347

  if (['problem', 'level_problem', 'calibration_problem'].includes(status)) {
    return <OperationSuggestedExplanation operationSuggested={metadata?.operation_suggested} />;
  } else if (['pending'].includes(status) && metadata?.info_to_calibrate) {
    return (
      <>
        <Alert severity="info" color="info">
          {t('devices_status.pending.explanation')}
        </Alert>
        <WarningCauseExplanation warningCause={metadata?.warning_cause} />
      </>
    );
  } else if (['pending', 'calibrating'].includes(status)) {
    return (
      <>
        <Alert severity="info" color="info">
          {t('calibration_in_progress')}
        </Alert>
        <WarningCauseExplanation warningCause={metadata?.warning_cause} />
      </>
    );
  } else if (['ok'].includes(status)) {
    return (
      <>
        {isInstalledLessThan15DaysAgo && (
          <Alert severity="info" color="info">
            {t('calibration_before_15_days')}
          </Alert>
        )}
        <WarningCauseExplanation warningCause={metadata?.warning_cause} />
      </>
    );
  }
};

const OperationSuggestedExplanation = ({
  operationSuggested
}: {
  operationSuggested: Parameters<typeof useOperationSuggestedInfo>[0];
}) => {
  const { t } = useTranslation();
  const { Icon, explanation } = useOperationSuggestedInfo(operationSuggested);

  if (!Icon || !explanation) {
    return (
      <Alert severity="error" color="error" icon={<Warning />}>
        {t(`device_status_problem_unknown_explanation`)}
      </Alert>
    );
  }

  return (
    <Alert severity="error" color="error" icon={<Icon />}>
      {explanation}
    </Alert>
  );
};

const WarningCauseExplanation = ({
  warningCause
}: {
  warningCause: Parameters<typeof useWarningCauseInfo>[0];
}) => {
  const { Icon, explanation } = useWarningCauseInfo(warningCause);

  if (warningCause === 'ice') return null; // Ignored for now for SiloConnect to avoid false positive until we'll have our first real case

  if (!Icon || !explanation) return null;

  return (
    <Alert severity="warning" color="warning" icon={<Icon />}>
      {explanation}
    </Alert>
  );
};

export default DeviceStatusExplanation;
