import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import {
  ICalibrationData,
  ICalibrationResponse,
  IGetGraphDataResponse,
  IGraphData
} from 'shared/model/api.model';
import { IDevice } from 'shared/model/device.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import Loading from 'shared/widgets/loading';
import GraphLevel from 'shared/widgets/visualization/graphLevel';
import TemperatureMetric from 'shared/widgets/visualization/temperatureMetric';
import PrivateComponent from 'shared/auth/privateComponent';
import CalibrateBtn from './actions/calibrateBtn';
import DeviceInfos from './deviceInfo';
import DeviceMap from './deviceMap';
import useInterval from 'shared/hooks/useInterval';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import { IRootState } from 'config/store';
import { useSelector } from 'react-redux';
import DeliveryBtn from './actions/deliveryBtn';
import DeviceStatusExplanation from 'shared/widgets/devices/deviceStatusExplanation';

const apiUrl = getEnvApiUrl();
const getGraphDataUrl = `${apiUrl}/v1/get-graph-data`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1)
    },
    loadingBox: {
      textAlign: 'center',
      margin: theme.spacing(1)
    },
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    },
    paperMap: {
      padding: theme.spacing(1),
      height: '100%'
    },
    p2: {
      padding: theme.spacing(2)
    },
    siloActions: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '5px'
    },
    visu: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      width: 'fit-content',
      height: '100%',
      '&>*': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    level: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
);

export interface IDefaultDeviceDetailsProps {
  data: IDevice;
}

const DefaultDeviceDetails = (props: IDefaultDeviceDetailsProps) => {
  const classes = useStyles();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [graphDatas, setGraphDatas] = useState<IGraphData[]>([]);
  const [lastLevel, setLastLevel] = useState<ICalibrationData[]>([]);
  const device = props.data;

  const load = useCallback(async () => {
    try {
      setLoading(true);
      setErrorMsg('');

      const fetchGraphData = axios.post<IGetGraphDataResponse>(getGraphDataUrl, {
        is_last_value: true,
        device_ids: [device.device_id],
        data_types: '__all__'
      });
      const fetchCalibrationsData = isSilo
        ? axios.get<ICalibrationResponse>(`${apiUrl}/v2/calibrations/`, {
            params: {
              idDevice: device.device_reference,
              type: 'level',
              page: 1,
              pageSize: 1
            }
          })
        : { data: { results: [] } };
      const [responseData, responseCalib] = await Promise.all([
        fetchGraphData,
        fetchCalibrationsData
      ]);

      setGraphDatas(responseData.data.data);
      setLastLevel(responseCalib?.data.results ?? []);
    } catch (e) {
      setErrorMsg(getRequestErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [device.device_id, device.device_reference, isSilo]);

  useInterval(load);

  const timeData = graphDatas?.find(graphData => graphData.data_type === 'lastMessageReceived');
  const positionData = graphDatas?.find(graphData => graphData.data_type === 'position');
  const levelPercentData = graphDatas?.find(graphData => graphData.data_type === 'level_percent');
  const temperature = graphDatas?.find(graphData => graphData.data_type === 'temperature');
  const level = graphDatas?.find(graphData => graphData.data_type === 'level');

  return (
    <Box className={classes.root}>
      {loading ? (
        <Paper elevation={3}>
          <Box p={2}>
            <Loading />
          </Box>
        </Paper>
      ) : (
        <>
          {errorMsg ? (
            <Box>{errorMsg}</Box>
          ) : (
            <>
              {graphDatas.length > 0 ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={isSilo ? 9 : 6}>
                    <Paper elevation={3} className={classes.p2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                          <DeviceInfos
                            device={device}
                            timeData={timeData}
                            positionData={positionData}
                            lastLevel={lastLevel}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={5}
                          xl={5}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Box className={classes.visu}>
                            {['ok', 'level_problem'].includes(device.status) && (
                              <GraphLevel
                                levelPercent={levelPercentData?.data_points[0].value}
                                legend={t('data_type_unit', {
                                  value: level?.data_points[0]?.filling
                                })}
                              />
                            )}

                            {isSilo && (
                              <DeviceStatusExplanation
                                status={device.status}
                                metadata={device.metadata}
                                installDate={device.device_install_date}
                              />
                            )}

                            <TemperatureMetric graphData={temperature} />

                            <Box className={classes.siloActions}>
                              {isSilo && (
                                <PrivateComponent resource="Calibrations" operation={['CREATE']}>
                                  <>
                                    <DeliveryBtn device={device} />
                                    <CalibrateBtn device={device} />
                                  </>
                                </PrivateComponent>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={isSilo ? 3 : 6}>
                    <Paper elevation={3} className={classes.paperMap}>
                      <DeviceMap data={graphDatas} height="100%" />
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default DefaultDeviceDetails;
